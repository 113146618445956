.avatar-icon {
    font-size: 1.2rem;
    padding-right: 10px;
}

.userActions {
    margin-right: .9rem;
}

.dropdownCancel {
    margin-left: 2px;
}