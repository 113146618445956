/**
 * Datepicker Styles
 */

.react-datepicker {
  border: none;
}

.react-datepicker-popper,
.react-datepicker {
  z-index: 1000;
}

.react-datepicker__month-container {
  border: none;
  box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.11), 0 10px 20px rgba(0, 0, 0, 0.05),
    0 2px 3px rgba(0, 0, 0, 0.06);
}

.react-datepicker__header {
  border: none;
  background: #fff;
  padding-top: 20px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__current-month {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif;
  color: #5a6169;
}

.react-datepicker__day--disabled {
  color: #ddd;
}

.react-datepicker__day--disabled:hover {
  background: transparent !important;
}

.react-datepicker__day {
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.react-datepicker__day:hover {
  background-color: #eceeef;
}

.react-datepicker__current-month {
  font-weight: 600;
}

.react-datepicker__day,
.react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
}

.react-datepicker__day--highlighted {
  background: #e6f2ff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  color: #fff;
  background: #007bff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--selected:hover {
  background: #006fe6;
}

.react-datepicker__header,
.react-datepicker__month-container {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.react-datepicker__header {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.react-datepicker {
  border-radius: 0.375rem;
}

.react-datepicker__navigation {
  top: 25px;
}

.react-datepicker__triangle:before {
  border-bottom-color: #e3e3e3 !important;
}

.react-datepicker__month {
  padding: 10px 15px;
}

/* Datepicker & Input Groups */

.input-group > .react-datepicker-wrapper .form-control {
  position: relative;
}

.input-group > .react-datepicker-wrapper:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .react-datepicker-wrapper:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .react-datepicker-wrapper ~ .react-datepicker-wrapper input {
  margin-left: -1px;
}

.input-group
  > .react-datepicker-wrapper
  ~ .react-datepicker-wrapper
  ~ .input-group-append {
  margin-left: -2px;
}

.input-group > .react-datepicker-wrapper .form-control:focus {
  z-index: 3;
}
