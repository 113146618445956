.signinBody {
  width: 100vw;
  height:100vh;
  background: #e4e4e4;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signinBox NavLink:hover {
  text-decoration: 'none' !important;
}

.signinBox {
  width: fit-content;
  height: fit-content;
  background: white;
  padding: 40px;
  display: grid;
  border-radius: 6px;
  text-align: center;
  box-shadow: 5px 5px 10px -10px grey;
}

.signinBox h6 {
  margin-top: 5px;
  margin-bottom: 20px;
}

.signinBox h6.qq {
  margin-top: 5px;
  margin-bottom: 0px;
}

.NameBox {
  display: flex;
  width: 100%;
}

.NameBox input:first-child {
  margin-right: 3px;
  border: none;
  background: #e4e4e4;
  border-top-left-radius: 10px;
  height: 45px;
  margin-bottom: 10px;
  padding: 15px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.NameBox input:last-child {
  margin-left: 3px;
  border: none;
  background: #e4e4e4;
  border-top-right-radius: 10px;
  height: 45px;
  margin-bottom: 10px;
  padding: 15px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.signinBox input {
  border: none;
  background: #f5f6f7;
  height: 45px;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 10px;
}

.signinBox button {
  border: none;
  width: 100%;
  height: 50px;
  font-size: 20px;
  background: #8add66;
  border-radius: 6px;
  color: white;
  cursor: pointer;
}

.signinBox input:focus{
  outline: none;
}

.signinBox button:focus{
  outline: none;
}

.termsBox {
  display: flex;
  margin: auto;
}

.termsBox span {
  margin-top: 11px;
  margin-left: 10px;
}

.signinBox .inputSpecs {
  font-size: .5rem;
  margin: 0 0 10px 0;
  text-align: left;
}

.termsLink:hover {
  text-decoration: none;
  color: #3fc108;
}