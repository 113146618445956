/* TableList */
.iconClass.search {
    padding-left: 1px;
}

.iconClass.times {
    margin-right: -10%;
    color: #fe5000;
}

.iconClass.times i {
  color: #fe5000;
}

@media only screen and (min-width: 1000px) {
  .iconClass.times {
      margin-right: -16%;
  }
}

@media only screen and (min-width: 1150px) {
    .iconClass.times {
        margin-right: -19%;
    }
}

/* NotFound */
#not-found {
    padding-top: 1rem;
    text-align: center;
}

/* Confirmation */
#confirmation {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-top: 100px;
    padding-left: 2%;
    padding-right: 2%;
}
  
#confirmation.card {
    border-radius: 10px;
}
  
#confirmation h3 {
    padding-left: 1%;
}
  
#confirmation li {
    padding-top: 20px;
}

/* ApplicationProfile */
.profileBox {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 9px -5px #868e96;
    margin-bottom: 2rem;
  }

  .profileBox input {
    border: 1px solid #e4e4e4;
    background-color: #f5f6f7;
    height: 45px;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 6px;
  }

  .profileBox input::placeholder {
    color: #cecece;
  }
  
  .profileBox button {
    border: none;
    width: 30%;
    height: 45px;
    font-size: 1rem;
    background: #8add66;
    border-radius: 6px;
    color: white;
    cursor: pointer;
  }
  
  .pbi:focus {
    border: 0;
    outline: 0;
  }
  
  .profileBox button:focus {
    outline: none;
  }

  .profileBox .inputSpecs {
    font-size: .5rem;
    margin: 0 0 10px 0;
    text-align: left;
  }

  /* TandC */
  #TandC {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  #TandC h1 {
    text-align: center;
    padding-top: 2%;
  }
  
  #TandC p {
    text-align: left;
  }
  
  #TandC h4 {
    text-align: left;
  }
  
  #TandC .text {
    margin-left: 280px;
    margin-right: 250px;
  }
  
  #TandC a {
    text-decoration: none;
  }

/* Settings*/
.settingsBox {
  background: white;
  width: 100%;
  padding: 2rem;
  border-radius: 10px;
  text-align: left;
  /* display: flex;
  flex-direction: column; */
  box-shadow: 3px 3px 9px -5px #868e96;
  margin-bottom: 2rem;
}

.settingsBox input {
  border: 1px solid #e4e4e4;
  background-color: #f5f6f7;
  height: 45px;
  width: 95%;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 6px;
}

.settingsBox input::placeholder {
  color: #cecece;
}

.settingsBox button {
  border: none;
  width: 25%;
  height: 35px;
  font-size: 1rem;
  background: #8add66;
  border-radius: 6px;
  color: white;
  cursor: pointer;
}

.pbi:focus {
  border: 0;
  outline: 0;
}

.settingsBox button:focus {
  outline: none;
}

.settingsBox .inputSpecs {
  font-size: .5rem;
  margin: 0 0 10px 0;
  text-align: left;
}

@media only screen and (min-width: 993px) {
  .iconClass.times {
      margin-right: -35px;
  }

  .settingsBox {
    width: 200%;
  }

  .settingsBox button {
    width: 20%;
    height: 45px;
    font-size: 1.2rem;
  }
}
