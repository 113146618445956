div#SideNavBars {
  position: relative;
}

a.LogoutBtn {
  border-radius: 10px;
  background: #007bff;
  padding: 7px;
  color: white;
  text-decoration: none;
  font-size: 15px;
  position: absolute;
  bottom: 15px;
  left: 20%;
  right: 20%;
  text-align: center;
}

.devSwitchDiv {
  margin: 1rem 1rem 0 1rem;
  text-align: center;
}

.devSwitch {
  width: 100%;
  background-color: #767676 !important;
  border-color: #767676 !important;
  color: #ffff !important;
  font-size: 15px !important;
  line-height: 1 !important;
}

p.inputSpecs {
  font-size: .5rem;
  text-align: center;
}

.devSwitchDiv.inputSpecs {
  font-size: .5rem;
  text-align: left;
}

a.LogoutBtn i {
  margin-right: 10px;
}

a.LogoutBtn span {
  margin-right: 0px;
}

a.LogoutBtn:hover {
  color: white;
  text-decoration: none;
}


img.PersonAvatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.mb-4.card.card-small {
  overflow-x: auto !important;
}

form.main-navbar__search {
  width: 70% !important;
}


@media (max-width: 768px) {
  a.LogoutBtn  {
    bottom: 80px;
  }


}
