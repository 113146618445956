.date-range .react-datepicker-wrapper {
  display: flex;
  flex: 1;
  max-width: 160px;
}

.date-range .react-datepicker__input-container {
  width: 100%;
}

.input-group-text {
  border: none !important;
  background-color: #ffff !important;
  margin-top: 3px;
}

@media (max-width: 575px) {
  .date-range .react-datepicker-wrapper {
    max-width: 100%;
  }
}
